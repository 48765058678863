.Home {
	margin-top: 1.5em;
	/* padding: 1em; */
	padding-left: 1em;
	padding-right: 1em;
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 672px;
	padding-bottom: 5%;
}

.Home-main {
	margin-top: 4em;
}

.Home-main,
.Home-header {
	display: flex;
	justify-content: center;
}

.Home-header-title {
	width: 100%;
	max-width: 672px;
}

.Home-header-spacer {
	width: 100%;
	max-width: 300px;
}

.Home-icon {
	height: 100px;
	mask-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ijc5My40IDYwNC41IDMwMCAzMDAiPjxwYXRoIGQ9Ik0xMDkzLjQgNjk4LjR2LTEwLjdjMC0zLS4xLTYtLjEtOS4xLS4yLTYuNi0uNi0xMy4yLTEuNy0xOS43LTEuMi02LjYtMy4xLTEyLjctNi4yLTE4LjctMy01LjktNi45LTExLjMtMTEuNi0xNi00LjctNC43LTEwLjEtOC42LTE2LTExLjYtNi0zLjEtMTIuMS01LTE4LjctNi4yLTYuNS0xLjItMTMuMS0xLjYtMTkuNy0xLjctMy0uMS02LS4xLTkuMS0uMUg4NzYuNmMtMyAwLTYgLjEtOS4xLjEtNi42LjItMTMuMi42LTE5LjcgMS43LTYuNiAxLjItMTIuNyAzLjEtMTguNyA2LjItNS45IDMtMTEuMyA2LjktMTYgMTEuNi00LjcgNC43LTguNiAxMC4xLTExLjYgMTYtMy4xIDYtNSAxMi4xLTYuMiAxOC43LTEuMiA2LjUtMS42IDEzLjEtMS43IDE5LjctLjEgMy0uMSA2LS4xIDkuMXYxMzMuN2MwIDMgLjEgNiAuMSA5LjEuMiA2LjYuNiAxMy4yIDEuNyAxOS43IDEuMiA2LjYgMy4xIDEyLjcgNi4yIDE4LjcgMyA1LjkgNi45IDExLjMgMTEuNiAxNiA0LjcgNC43IDEwLjEgOC42IDE2IDExLjYgNiAzLjEgMTIuMSA1IDE4LjcgNi4yIDYuNSAxLjIgMTMuMSAxLjYgMTkuNyAxLjcgMyAuMSA2IC4xIDkuMS4xaDEzMy43YzMgMCA2LS4xIDkuMS0uMSA2LjYtLjIgMTMuMi0uNiAxOS43LTEuNyA2LjYtMS4yIDEyLjctMy4xIDE4LjctNi4yIDUuOS0zIDExLjMtNi45IDE2LTExLjZzOC42LTEwLjEgMTEuNi0xNmMzLjEtNiA1LTEyLjEgNi4yLTE4LjcgMS4yLTYuNSAxLjYtMTMuMSAxLjctMTkuNy4xLTMgLjEtNiAuMS05LjF2LTEyM3oiLz48L3N2Zz4=');
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
}

.Home-screenshot-container {
	/* filter: drop-shadow(0 0 0.2em lightgray); */
	margin: 1em;
}

.Home-screenshot {
	width: 100%;
	max-width: 300px;
	mask: url(iPhone-6-5-mask.svg) center/100% 100% no-repeat;
	
}

.Home-body > *,
.Home-box > :first-child {
	margin-bottom: 1em;
}

.Home-box {
	background-color: #f1f1f1;
	border-radius: 1em;
	padding: 1em;
}

.Home-body ul {
	padding-left: 2em;
}

.Home-side {
	flex-shrink: 0.5;
}

@media screen and (max-width: 1300px) {
	/* .Home-side, */
	.Home-header-spacer {
		display: none;
	}
	.Home {
		padding-bottom: 15%;
	}
}

@media screen and (max-width: 850px) {
	.Home-side,
	.Home-header-spacer {
		display: none;
	}
	.Home {
		padding-bottom: 20%;
	}
}

@media screen and (max-width: 500px) {
	.Home-side,
	.Home-header-spacer {
		display: none;
	}
	.Home {
		padding-bottom: 35%;
		margin-top: 0.5em;
	}
	.Home-icon {
		height: 60px;
	}
}

