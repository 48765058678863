.Footer {
	border-top: solid 1px lightgray;
	font-size: smaller;
	margin-bottom: 3em;	
	background-color: #fff;
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 100px;
	margin-bottom: 0px;
}

.Footer-container {
	position: relative;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;                                                                   
}

.Footer-container > * {
	padding: 1em;
	font-weight: 500;
}

@media screen and (max-width: 1080px) {
	.Footer {
		padding-bottom: 5%;
		height: 35px;
	}
	
}

@media screen and (max-width: 850px) {
	.Footer {
		padding-bottom: 8%;
		height: 35px;
	}
	
}

@media screen and (max-width: 500px) {
	.Footer {
		padding-bottom: 10%;
		height: 95px;
	}
}