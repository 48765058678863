.TopMessage {
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	padding-top: 1em;
	padding-left: 1em;
	padding-right: 1em;
	transition: 0.5s;
	pointer-events: none;
}

.TopMessage.hidden {
	top: -5em;
	opacity: 0;
}

.TopMessage > * {
	box-shadow: 0px 0px 1em 0px lightgrey;
	margin: 0;
	pointer-events: auto;
}
