@import-normalize;

:root {
	--color-blue: rgb(0, 122, 255);
	--color-blue-darker: rgb(0, 91, 189);
	--color-green: rgb(52, 199, 89);
	--color-red: rgb(255, 59, 48);
	--color-secondary-background: #f7f7f7;
	--fade-time: 0.2s;
	--line-height: 1.5em;
}

* {
	margin: 0;
	padding: 0;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica', sans-serif;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a,
.link {
	color: var(--color-blue);
	text-decoration: none;
	transition: color var(--fade-time);
	border: none;
	background: none;
	appearance: none;
	padding: 0;
	cursor: pointer;
	font: inherit;
}

a:hover,
.link:hover {
	color: var(--color-blue-darker);
}

.plainButton {
	color: black;
	transition: color var(--fade-time);
	border: none;
	background: none;
	appearance: none;
	padding: 0;
	cursor: pointer;
	font: inherit;
}

.plainButton:hover {
	color: rgb(92, 92, 92);
}

.readableWidth {
	max-width: 672px;
	margin-left: auto;
	margin-right: auto;
}

.largerWidth {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.button {
	border: none;
	background-color: black;
	border-radius: 0.5em;
	color: white;
	text-decoration: none;
	padding: 0.5em;
	font-weight: bold;
	font-size: medium;
	transition: var(--fade-time);
}

h1 {
	text-align: center;
}

.button.big {
	padding: 1em;
	font-size: larger;
}

p {
	font-size: large;
	line-height: var(--line-height);
}

li {
	line-height: var(--line-height);
	font-size: large;
}

.button:active {
	background-color: #888888 !important;
}

.button:hover:not(:disabled) {
	background-color: #444444;
	color: white;
	cursor: pointer;
}

.button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

hr {
	border-style: solid;
	color: lightgray;
}

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.form > * {
	margin-bottom: 1em;
}

.form > span {
	text-align: center;
}

.formInput,
.form input {
	box-sizing: border-box;
	border: none;
	border-radius: 0.5em;
	padding: 0.5em;
	resize: vertical;
	transition: box-shadow var(--fade-time);
	font: inherit;
}

.formInput:focus-visible,
.form input:focus-visible {
	outline: none;
	box-shadow: 0 0 0 0.25em var(--color-blue);
}
