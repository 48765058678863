.CorporatePage-main {
	margin-top: 4em;
	padding: 1em;
	padding-bottom: 5%;
}

.CorporatePage-main section > * {
	margin-bottom: 1em;
}

.CorporatePage-main ul,
.CorporatePage-main ol {
	padding-left: 2em;
}

.CorporatePage-main td:first-child {
	vertical-align: top;
	padding-right: 1em;
}

.CorporatePage-main h2::before {
	content: '';
	display: block;
	position: relative;
	width: 0;
	height: 3em;
	margin-top: -3em;
}

@media screen and (max-width: 1600px) {
	.CorporatePage-main {
		padding-bottom: 10%;
	}
	
}

@media screen and (max-width: 1080px) {
	.CorporatePage-main {
		padding-bottom: 20%;
	}
	
}

@media screen and (max-width: 500px) {
	.CorporatePage-main {
		padding-bottom: 35%;
	}
	
}