.Header {
	border-bottom: solid 1px lightgray;
	background-color: #f7f7f7;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
}

.Header > * {
	padding: 1em;
}

.Header-title {
	text-decoration: none;
	color: black;
	font-weight: 500;
	font-size: x-large;
}
