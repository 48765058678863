.Message {
	padding: 1em;
	color: white;
	border-radius: 0.5em;
	margin-bottom: 1em;
	font-weight: 500;
}

.Message.success {
	background-color: var(--color-green);
}

.Message.failure {
	background-color: var(--color-red);
}
