.Loading {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	flex-direction: column;
}

.Loading svg {
	margin-bottom: 0.5em;
}
