.Contact-form {
	display: flex;
	flex-direction: column;
}

.Contact-form > *:not(:last-child) {
	margin-bottom: 1em;
}

.Contact-form > label {
	display: flex;
	flex-direction: column;
	font-weight: bold;
}

.Contact-form > label :first-child {
	margin-bottom: 0.5em;
}

.Contact-form input,
.Contact-form textarea,
.Contact-form select {
	border: 1px solid lightgray;
	border-radius: 0.5em;
	padding: 0.5em;
	resize: vertical;
	transition: var(--fade-time);
	font-family: inherit;
	font-size: inherit;
}

.Contact-form *:focus-visible {
	outline: none;
	box-shadow: 0 0 0 0.25em var(--color-blue);
}

.Contact-required {
	color: red;
	font-weight: bold;
}